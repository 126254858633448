import "../styles/FooterStyle.css";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import { FiMail } from "react-icons/fi"
import React from 'react';
const Footer = () => {
  const [year] = React.useState(() => new Date().getFullYear());
  const openMail = () => {
    window.open('mailto:simran.ghimire2027@gmail.com', '_blank');
  };

  const openGithub = () => {
    window.open('https://github.com/simranghimire07', '_blank');
  };

  const openLinkedIn = () => {
    window.open('https://www.linkedin.com/in/simranghimire', '_blank');
  };
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="content">
          &copy; Simran Ghimire
          <span id="year">{year}</span>
        </div>
        <div className="seprator">|</div>
        <div className="social-icon">
          <FiMail onClick={openMail} aria-hidden="true" />
          <FaGithub onClick={openGithub} />
          <FaLinkedin onClick={openLinkedIn} />
        </div>
      </div>

    </div>
  )
}
export default Footer