import { FiMail } from "react-icons/fi";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import "../styles/FormStyle.css";
import { useForm, ValidationError } from "@formspree/react";

const ContactForm = () => {

  return (
    <div className="contactForm" id="contact">

      <div className="getInTouch">
        <h2 className="heading">Let's Get In Touch</h2>
        <p>I'm always up for a chat. Feel free to reach out if you have any questions, ideas, or just want to say hi!</p>
        <div className="contact-info">
          <p> <FiMail /><span>simran.ghimire2027@gmail.com</span></p>
          <p><IoPhonePortraitOutline /><span>9861503274</span></p>
          <p><FaLocationDot /> <span>Kathmandu, Nepal</span></p>
        </div>
      </div>

      <div className="form-container">
        <h2>Say Something</h2>
        <form action="https://formspree.io/f/meqbpwkd" method="post">
          <input type="text" name="name" placeholder="Your Name" required />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea rows="6" placeholder="Message" required />
          <button className="btn">Submit</button>
        </form>
      </div>
    </div>
  )
}
export default ContactForm