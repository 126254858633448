import "../styles/HeroImgStyle.css";
import { Link } from "react-scroll";

const HeroImg = () => {
  const cv_link = `${process.env.PUBLIC_URL}/Simran Ghimire.pdf`;
  return (
    <div className="hero" id="home">
      <div className="heroimg"></div>
      <div className="content">
        <p>👋 Hi, I'm Simran.</p>
        <h1> Someone willing to learn...</h1>
        <div>

          <a href={cv_link} download={true}>
            <button className="btn">Download My CV</button>
          </a>
          {/* <Link to="project" className="btn" smooth spy>
            Projects
          </Link>
          <Link to="contact" className="btn-light" smooth spy>
            Contact
          </Link> */}
        </div>
      </div>
    </div>
  );
};
export default HeroImg;
