import React from 'react';
import '../styles/Timeline.css';

const TimelineEvent = ({ thumbnail, title, description }) => (
  <div className="timeline-event">
    <div className="timeline-event-icon"></div>
    <div className="timeline-event-copy">
      <h3 className='timeline-event-title'>{title}</h3>
      <p className="timeline-event-thumbnail">{thumbnail}</p>
      <p className='timeline-event-description'>{description}</p>
    </div>
  </div>
);
const TimeLine = () => {
  return (
    <div className="timeline" id="about">
      {/* <h2>My IT Journey</h2>
      <span>See my progress in IT.</span> */}

      <div className="summary">
        <h3>My Journey</h3>
      </div>
      < TimelineEvent
        title="Embarking on IT Journey"
        thumbnail="Bachelor in Computer Application, 2019 - Present"
        description="Initiated a Bachelor's degree in Computer Application at NCCS College, focusing on core IT skills and technologies. Developed a strong foundation in programming, web development, and database management. Engaged in various projects to apply theoretical knowledge and enhance practical skills."
      />
      <TimelineEvent
        title="Training at Mind Risers Consortium Pvt. Ltd."
        thumbnail="MERN Stack, July 2023 - October 2023"
        description="During training at Mind Risers, I have explored React principles for creating dynamic user interfaces, utilized Tailwind CSS for efficient styling, and delved into Node.js and Express.js for server-side development, while also mastering MongoDB for efficient data storage and retrieval."
      />
      <TimelineEvent
        title="Intern at NCCS Software"
        thumbnail="Laravel Developer, Decemeber 2023 - April 2024"
        description="As a Laravel developer intern at NCCS Software, I have acquired extensive hands-on experience in building modern web applications using the Laravel PHP framework and the Livewire library, leveraging their powerful features to create secure, scalable, and dynamic user interfaces while following best development practices and collaborating effectively within project teams."
      />
    </div>
  )
}
export default TimeLine;